import React, { lazy, Suspense } from 'react';
import { Switch, Route, Redirect, useLocation } from 'react-router-dom';
import { AnimatePresence, motion } from 'framer-motion';
import { ScaleLoader } from 'react-spinners';

import {
  LeftSidebar,
  PresentationLayout
} from './layout-blueprints';

import LoadingScreen from './pages/LoadingScreen';
import PageError505 from './pages/Errors/PageError505';



const UpdateUserInfo = lazy(() => import('./pages/UpdateUserInfo'));

const Login = lazy(() => import('./pages/Login'));
const Dashboard = lazy(() => import('./pages/Dashboard'));

const Users = lazy(() => import('./pages/Users'));

const Schools = lazy(() => import('./pages/Schools/schools'));
const Schoolinfo = lazy(() => import('./pages/Schools/schoolInfo'));
const Menus = lazy(() => import('./pages/Schools/menus'));
const Menuinfo = lazy(() => import('./pages/Schools/menuInfo'));
const Accountinfo = lazy(() => import('./pages/Schools/account'));

const CourseApprove = lazy(() => import('./pages/Approve/course'));

const Prepaid = lazy(() => import('./pages/Tickets/prepaid'));
const PrepaidRefund = lazy(() => import('./pages/Tickets/prepaidrefund'));
const Paylater = lazy(() => import('./pages/Tickets/paylater'));
const PaylaterRefund = lazy(() => import('./pages/Tickets/paylaterrefund'));
const Services = lazy(() => import('./pages/Tickets/service'));
const ServicesRefund = lazy(() => import('./pages/Tickets/servicerefund'));
const UsedList = lazy(() => import('./pages/Tickets/usedlist'));
const UnusedList = lazy(() => import('./pages/Tickets/unusedlist'));
const PaidRefund = lazy(() => import('./pages/Tickets/paidrefund'));
const TicketStop = lazy(() => import('./pages/Tickets/ticketstop'));

const ChargeApprove = lazy(() => import('./pages/Charge/approve'));
const ChargeCharge = lazy(() => import('./pages/Charge/charge'));
const ChargeList = lazy(() => import('./pages/Charge/chargeList'));
const ChargeCalc = lazy(() => import('./pages/Charge/calc'));
const ChargeCalcList = lazy(() => import('./pages/Charge/calcList'));
const ChargeCalcListDetail = lazy(() => import('./pages/Charge/calcListDetail'));

const ReservePay = lazy(() => import('./pages/Reserve/pay'));
const ReserveRefund = lazy(() => import('./pages/Reserve/refund'));
const ReserveExpired = lazy(() => import('./pages/Reserve/expired'));
const ReserveViews = lazy(() => import('./pages/Reserve/views'));

const SurveyReg = lazy(() => import('./pages/Survey/reg'));
const SurveyViews = lazy(() => import('./pages/Survey/views'));
const SurveyCalc = lazy(() => import('./pages/Survey/calc'));
const SurveyMenu = lazy(() => import('./pages/Survey/menu'));

const Points = lazy(() => import('./pages/Points/point'));
const PointsList = lazy(() => import('./pages/Points/issuelist'));
const PointsUsed = lazy(() => import('./pages/Points/usedlist'));

const Products = lazy(() => import('./pages/Goods/products'));
const ProductSales = lazy(() => import('./pages/Goods/sales'));
const ProductCalcs = lazy(() => import('./pages/Goods/calcs'));

const Calcs = lazy(() => import('./pages/Calcs/calc'));
const CalcsPay = lazy(() => import('./pages/Calcs/pay'));
const CalcsRefund = lazy(() => import('./pages/Calcs/refund'));
const CalcsSales = lazy(() => import('./pages/Calcs/sales'));
const CalcsInvoices = lazy(() => import('./pages/Calcs/invoice'));
const CalcsPaylater = lazy(() => import('./pages/Calcs/paylater'));
const CalcsKiosk = lazy(() => import('./pages/Calcs/kiosk'));

const ViewsCourse = lazy(() => import('./pages/Views/course'));
const ViewsShop = lazy(() => import('./pages/Views/shop'));
const ViewsTicket = lazy(() => import('./pages/Views/ticket'));
const ViewsMealType = lazy(() => import('./pages/Views/mealtype'));

const AboutUs = lazy(() => import('./pages/Ops/aboutus'));
const Alarm = lazy(() => import('./pages/Ops/alarm'));
const WriteAlarm = lazy(() => import('./pages/Ops/alarmWrite'));
const Faq = lazy(() => import('./pages/Ops/faq'));
const WriteFaq = lazy(() => import('./pages/Ops/faqWrite'));
const Notice = lazy(() => import('./pages/Ops/notice'));
const WriteNotice = lazy(() => import('./pages/Ops/noticeWrite'));
const One2One = lazy(() => import('./pages/Ops/one2one'));
const WriteAnswer = lazy(() => import('./pages/Ops/one2oneWrite'));
const Privacy = lazy(() => import('./pages/Ops/privacy'));
const Terms = lazy(() => import('./pages/Ops/terms'));
const Online = lazy(() => import('./pages/Ops/online'));
const AutoRenewal = lazy(() => import('./pages/Ops/autorenewal'));
const DeleteAccountInfo = lazy(() => import('./pages/Ops/deleteAccountInfo'));

const Levels = lazy(() => import('./pages/Systems/level'));
const Managers = lazy(() => import('./pages/Systems/manager'));



const Routes = () => {
  const location = useLocation();

  const pageVariants = {
    initial: {
      opacity: 0,
      scale: 0.99
    },
    in: {
      opacity: 1,
      scale: 1
    },
    out: {
      opacity: 0,
      scale: 1.01
    }
  };

  const pageTransition = {
    type: 'tween',
    ease: 'anticipate',
    duration: 0.4
  };

  const SuspenseLoading = () => {
    return (
      <>
        <div className="d-flex align-items-center flex-column vh-100 justify-content-center text-center py-3">
          <div className="d-flex align-items-center flex-column px-4">
            <ScaleLoader color={'#3c44b1'} loading={true} />
          </div>
          <div className="text-muted font-size-xl text-center pt-3">
            잠시 기다려 주세요
            <span className="font-size-lg d-block text-dark text-center">
              데이터 로딩 중 입니다
            </span>
          </div>
        </div>
      </>
    );
  };

  return (
    <AnimatePresence>
      <LoadingScreen key="LoadingScreen"/>
      <Suspense fallback={<SuspenseLoading />}>

        <Switch>

          <Redirect exact from="/" to="/Login" />
          
          <Route path={['/Login']}>
            <PresentationLayout>          
              <Switch location={location} key={location.pathname}>
                <motion.div
                  initial="initial"
                  animate="in"
                  exit="out"
                  variants={pageVariants}
                  transition={pageTransition}>
                  <Route path="/Login" component={Login} />
                </motion.div>
              </Switch>
            </PresentationLayout>
          </Route>

{ localStorage.getItem("accessToken")!==null && localStorage.getItem("accessToken")?.length > 0 ?

          <Route
            path={[
              '/UpdateUserInfo',
              '/Dashboard',
              '/Users',
              '/Schools/schools',
              '/Schools/schoolinfo',
              '/Schools/menus',
              '/Schools/menuinfo',
              '/Schools/account',
              '/Approve/course',
              '/Tickets/prepaid',
              '/Tickets/prepaidrefund',
              '/Tickets/paylater',
              '/Tickets/paylaterrefund',
              '/Tickets/service',
              '/Tickets/servicerefund',
              '/Tickets/usedlist',
              '/Tickets/unusedlist',
              '/Tickets/paidrefund',
              '/Tickets/ticketstop',
              '/Charge/approve',
              '/Charge/charge',
              '/Charge/chargeList',
              '/Charge/calc',
              '/Charge/calcList',
              '/Charge/calcListDetail',
              '/Reserve/pay',
              '/Reserve/refund',
              '/Reserve/expired',
              '/Reserve/views',
              '/Survey/reg',
              '/Survey/views',
              '/Survey/calc',
              '/Survey/menu',
              '/Points/point',
              '/Points/issuelist',
              '/Points/usedlist',
              '/Goods/products',
              '/Goods/sales',
              '/Goods/calcs',              
              '/Calcs/calc',
              '/Calcs/pay',
              '/Calcs/refund',
              '/Calcs/sales',
              '/Calcs/invoice',
              '/Calcs/paylater',
              '/Calcs/kiosk',
              '/Views/course',
              '/views/shop',
              '/Views/ticket',
              '/Views/mealtype',
              '/Ops/aboutus',
              '/Ops/alarm',
              '/Ops/alarmWrite',
              '/Ops/faq',
              '/Ops/faqWrite',
              '/Ops/notice',
              '/Ops/noticeWrite',
              '/Ops/one2one',
              '/Ops/one2oneWrite',
              '/Ops/privacy',
              '/Ops/terms',
              '/Ops/online',
              '/Ops/autorenewal',
              '/Ops/deleteAccountInfo',
              '/Systems/level',
              '/Systems/manager'
            ]}>
            <LeftSidebar>
              <Switch location={location} key={location.pathname}>
                <motion.div
                  initial="initial"
                  animate="in"
                  exit="out"
                  variants={pageVariants}
                  transition={pageTransition}>
                  <Route
                    path="/UpdateUserInfo"
                    component={UpdateUserInfo}
                  />                    
                  <Route
                    path="/Dashboard"
                    component={Dashboard}
                  />
                  <Route
                    path="/Users"
                    component={Users}
                  />
                  <Route
                    path="/Schools/schools"
                    component={Schools}
                  />
                  <Route
                    path="/Schools/schoolinfo"
                    component={Schoolinfo}
                  />
                  <Route
                    path="/Schools/menus"
                    component={Menus}
                  />
                  <Route
                    path="/Schools/menuinfo"
                    component={Menuinfo}
                  />
                  <Route
                    path="/Schools/account"
                    component={Accountinfo}
                  />
                  <Route
                    path="/Approve/course"
                    component={CourseApprove}
                  />  
                  <Route
                    path="/Tickets/prepaid"
                    component={Prepaid}
                  />
                  <Route
                    path="/Tickets/prepaidrefund"
                    component={PrepaidRefund}
                  />                  
                  <Route
                    path="/Tickets/paylater"
                    component={Paylater}
                  />
                  <Route
                    path="/Tickets/paylaterrefund"
                    component={PaylaterRefund}
                  />                   
                  <Route
                    path="/Tickets/service"
                    component={Services}
                  />
                  <Route
                    path="/Tickets/servicerefund"
                    component={ServicesRefund}
                  />                  
                  <Route
                    path="/Tickets/usedlist"
                    component={UsedList}
                  />
                  <Route
                    path="/Tickets/unusedlist"
                    component={UnusedList}
                  />
                  <Route
                    path="/Tickets/paidrefund"
                    component={PaidRefund}
                  />
                  <Route
                    path="/Tickets/ticketstop"
                    component={TicketStop}
                  />                  
                  <Route
                    path="/Charge/approve"
                    component={ChargeApprove}
                  />
                  <Route
                    path="/Charge/charge"
                    component={ChargeCharge}
                  />
                  <Route
                    path="/Charge/chargeList"
                    component={ChargeList}
                  />
                  <Route
                    path="/Charge/calc"
                    component={ChargeCalc}
                  />
                  <Route
                    path="/Charge/calcList"
                    component={ChargeCalcList}
                  />
                  <Route
                    path="/Charge/calcListDetail"
                    component={ChargeCalcListDetail}
                  />
                  <Route
                    path="/Reserve/pay"
                    component={ReservePay}
                  />
                  <Route
                    path="/Reserve/refund"
                    component={ReserveRefund}
                  />
                  <Route
                    path="/Reserve/expired"
                    component={ReserveExpired}
                  />
                  <Route
                    path="/Reserve/views"
                    component={ReserveViews}
                  />
                  <Route
                    path="/Survey/reg"
                    component={SurveyReg}
                  />
                  <Route
                    path="/Survey/views"
                    component={SurveyViews}
                  />
                  <Route
                    path="/Survey/calc"
                    component={SurveyCalc}
                  />
                  <Route
                    path="/Survey/menu"
                    component={SurveyMenu}
                  />                  
                  <Route
                    path="/Points/point"
                    component={Points}
                  />
                  <Route
                    path="/Points/issuelist"
                    component={PointsList}
                  />
                  <Route
                    path="/Points/usedlist"
                    component={PointsUsed}
                  />
                  <Route
                    path="/Goods/products"
                    component={Products}
                  />
                  <Route
                    path="/Goods/sales"
                    component={ProductSales}
                  />
                  <Route
                    path="/Goods/calcs"
                    component={ProductCalcs}
                  />                  
                  <Route
                    path="/Calcs/calc"
                    component={Calcs}
                  />
                  <Route
                    path="/Calcs/pay"
                    component={CalcsPay}
                  />
                  <Route
                    path="/Calcs/refund"
                    component={CalcsRefund}
                  />
                  <Route
                    path="/Calcs/sales"
                    component={CalcsSales}
                  />
                  <Route
                    path="/Calcs/invoice"
                    component={CalcsInvoices}
                  />
                  <Route
                    path="/Calcs/paylater"
                    component={CalcsPaylater}
                  />
                  <Route
                    path="/Calcs/kiosk"
                    component={CalcsKiosk}
                  />
                  <Route
                    path="/Views/course"
                    component={ViewsCourse}
                  />
                  <Route
                    path="/views/shop"
                    component={ViewsShop}
                  />
                  <Route
                    path="/Views/ticket"
                    component={ViewsTicket}
                  />
                  <Route
                    path="/Views/mealtype"
                    component={ViewsMealType}
                  />                     
                  <Route
                    path="/Ops/aboutus"
                    component={AboutUs}
                  />
                  <Route
                    path="/Ops/alarm"
                    component={Alarm}
                  />
                  <Route
                    path="/Ops/alarmWrite"
                    component={WriteAlarm}
                  />                  
                  <Route
                    path="/Ops/faq"
                    component={Faq}
                  />
                  <Route
                    path="/Ops/faqWrite"
                    component={WriteFaq}
                  />                  
                  <Route
                    path="/Ops/notice"
                    component={Notice}
                  />
                  <Route
                    path="/Ops/noticeWrite"
                    component={WriteNotice}
                  />                  
                  <Route
                    path="/Ops/one2one"
                    component={One2One}
                  />
                  <Route
                    path="/Ops/one2oneWrite"
                    component={WriteAnswer}
                  />                  
                  <Route
                    path="/Ops/privacy"
                    component={Privacy}
                  />
                  <Route
                    path="/Ops/terms"
                    component={Terms}
                  />
                  <Route
                    path="/Ops/online"
                    component={Online}
                  />
                  <Route
                    path="/Ops/autorenewal"
                    component={AutoRenewal}
                  />
                  <Route
                    path="/Ops/deleteAccountInfo"
                    component={DeleteAccountInfo}
                  />
                  <Route
                    path="/Systems/level"
                    component={Levels}
                  />
                  <Route
                    path="/Systems/manager"
                    component={Managers}
                  />                                                                                                                                                                                                                                                                                                
                </motion.div>
              </Switch>
            </LeftSidebar>
          </Route>

: <Redirect to="/Login" /> }

          <Route path={"*"} component={PageError505}/>
          
        </Switch>

      </Suspense> 
    </AnimatePresence>
  );
};

export default Routes;



