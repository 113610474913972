import React from 'react';
import clsx from 'clsx';
import { connect } from 'react-redux';
import { setSidebarToggleMobile } from '../../reducers/ThemeOptions';
import HeaderUserbox from '../../layout-components/HeaderUserbox';
import { Button } from 'reactstrap';

import { useHistory } from "react-router";



const Header = (props) => {
  const {
    headerShadow,
    headerBgTransparent
  } = props;

  const history = useHistory();
  const clickLogout = () => {
    localStorage.clear();
    setTimeout(function() {history.push("/Login");},500);
  }  

  return (
    <>
      <div
        className={clsx('app-header', {
          'app-header--shadow': headerShadow,
          'app-header--opacity-bg': headerBgTransparent
        })}>
        <div className="app-header--pane">
          <HeaderUserbox />
        </div>
        <div className="app-header--pane">
          <Button size="sm" color="dark" onClick={clickLogout}>로그아웃</Button>&nbsp;
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  headerShadow: state.ThemeOptions.headerShadow,
  headerBgTransparent: state.ThemeOptions.headerBgTransparent,
  sidebarToggleMobile: state.ThemeOptions.sidebarToggleMobile
});

const mapDispatchToProps = (dispatch) => ({
  setSidebarToggleMobile: (enable) => dispatch(setSidebarToggleMobile(enable))
});

export default connect(mapStateToProps, mapDispatchToProps)(Header);



