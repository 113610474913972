import React from 'react';

import { Modal, ModalBody } from 'reactstrap';
import { ScaleLoader } from 'react-spinners';

import { useSelector } from 'react-redux';

export default function LoadingScreen() {
  const loadingStores = useSelector(state => state.loading);
  return (
    <>
      <Modal zIndex={2000} centered isOpen={loadingStores.LOADING}>
        <ModalBody>
          <div className="d-flex align-items-center flex-column justify-content-center text-center py-3">
            <div className="d-flex align-items-center flex-column px-4">
              <ScaleLoader color={'#3c44b1'} loading={true} />
            </div>
            <div className="font-size-xl text-center pt-3">
              잠시 기다려 주세요
              <span className="font-size-lg d-block text-dark text-center">
                데이터 로딩 중 입니다
              </span>
            </div>
          </div>
        </ModalBody>
      </Modal>
    </>
  );
}