import React from 'react';

import { useSelector } from 'react-redux';



const HeaderUserbox = () => {
  const loginDataStore = useSelector(state => state.loginReducer);
  const userLoginInfo = loginDataStore.data?.userLoginInfo || [];

  return (
    <>
          <div className="d-none d-xl-block pl-2">
            <div className="font-weight-bold">
              {userLoginInfo?.userRole ? <>
                  {userLoginInfo.userRole[0]?.authority==="ROLE_ADMIN" ? '학식 운영자 화면' : <>{userLoginInfo?.schoolName}</> } - {userLoginInfo?.userName} ({userLoginInfo?.userAccount})
              </> : ''}
            </div>
          </div>
    </>
  );
};

export default HeaderUserbox;



