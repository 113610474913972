export const LOGIN_REQUEST       = 'login/REQUEST';
export const LOGIN_LOGIN_SUCCESS = 'login/LOGIN_SUCCESS';
export const LOGIN_LOGIN_FAIL    = 'login/LOGIN_FAIL';
 
export function loginLogin(payload,apicall){
  return {
    type: LOGIN_REQUEST,
    payload,
    apicall
  }
}
 
export function loginLoginSuccess(data){
  return {
    type: LOGIN_LOGIN_SUCCESS,
    data
  }
}
 
export function loginLoginFail(error){
  return {
    type: LOGIN_LOGIN_FAIL,
    error
  }
}