import * as actions from "../actions/loginActions";

const loginState = {}

function loginReducer(state=loginState, action) {
    switch(action.type){
        case actions.LOGIN_REQUEST:
          return {
            ...state,
            payload: action.payload,
            data: {},
            error: {}
          }
        case actions.LOGIN_LOGIN_SUCCESS:
          return {
            ...state,
            data: action.data
          }
        case actions.LOGIN_LOGIN_FAIL:
          return {
            ...state,
            error: action.error
          }
        default:
          return state;
      }
    };

export default loginReducer;