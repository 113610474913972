import React from 'react';

import { UncontrolledTooltip } from 'reactstrap';

import { Bell, Activity, Calendar } from 'react-feather';

import { NavLink } from 'react-router-dom';



import { useSelector } from 'react-redux';

import { dashboardPage } from '../../utils/ConstUtils'



const SidebarFooter = () => {
  const loginDataStore = useSelector(state => state.loginReducer);
  const userLoginRole = loginDataStore.data?.userLoginInfo?.userRole || [];
  
  
  
  return (
    <>
      <div className="app-sidebar--footer">
        <ul>
          <li>
            <NavLink to={dashboardPage}>
              <div className="btn btn-sm btn-transition-none px-2 mx-2" id="AnalyticsDashboardTooltip">
              <Activity /></div>
            </NavLink>
            <UncontrolledTooltip
              target="AnalyticsDashboardTooltip"
              container=".app-sidebar--footer">
              대시보드 바로가기
            </UncontrolledTooltip>
          </li>

{ userLoginRole[0]?.authority === "ROLE_ADMIN" ?

          <li>
            <NavLink to="/Ops/alarm">
              <div className="btn btn-sm btn-transition-none px-2 mx-2" id="CryptoTooltip">
              <Bell /></div>
            </NavLink>
            <UncontrolledTooltip
              target="CryptoTooltip"
              container=".app-sidebar--footer">
              알람 메시지 관리
            </UncontrolledTooltip>
          </li>

: '' }

          <li>
            <NavLink to="/Calcs/calc">
              <div className="btn btn-sm btn-transition-none px-2 mx-2" id="ButtonsPageTooltip">
              <Calendar /></div>
            </NavLink>
            <UncontrolledTooltip
              target="ButtonsPageTooltip"
              container=".app-sidebar--footer">
              정산현황 보기
            </UncontrolledTooltip>
          </li>
        </ul>
      </div>
    </>
  );
};

export default SidebarFooter;
