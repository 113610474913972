import { baseUrl } from "../config/config"
import axios from "axios";
import { toast, Flip } from 'react-toastify';



export const stringConstantValues = {
    "copyrights": "Copyright 2024 © ㈜에이비피 Corp. All Rights Reserved",
    "version": "version : 1.1.206 (2024-10-15-01)"
}



export const initialPage = '/Dashboard';
export const dashboardPage = '/Dashboard';
export const initialPageWaitTime = 1500;



export function refreshToken() {
  if(localStorage.getItem("stayLogin") === "true") {
    let body = {
      refreshToken: localStorage.getItem("refreshToken")
    };

    axios
      .post(baseUrl + "/login", body)
      .then((res) => {
        if(res.data.status==="200") {
          localStorage.setItem("accessToken", res.headers.access_token);
          localStorage.setItem("refreshToken", res.headers.refresh_token);
          toastNoti('접근권한 시간이 만료되어, 권한을 갱신했습니다. 직전의 작업을 다시 진행해주세요.');
        } else {
          toastNoti(res.data.error);
        }
      })
      .catch(function (error) {
        toastNoti("서버와의 통신이 원활치 않습니다. 잠시 후 다시 시도해주세요.");
        if(error?.reponse?.error) toastNoti("오류 : " + error.reponse.error);
    });
  } else {
    toastNoti('접근권한 시간이 만료되었습니다. 다시 로그인 해 주세요.');
  }
}

export const toastNoti = (errorMessage) => {
  toast.error(errorMessage, {
    containerId: 'C',
    transition: Flip
  });
};



// textCut('가나다라마바사', 5, '...')
export function textCut(txt, len, lastTxt) {
    if(txt===null) return txt;
    
    if (len === "" || len === null) { // 기본값
        len = 20;
    }
    if (lastTxt === "" || lastTxt === null) { // 기본값
        lastTxt = "...";
    }
    if (txt.length > len) {
        txt = txt.substr(0, len) + lastTxt;
    }
    return txt;
}



//한 페이지당 표시되는 게시물 수
export const RECORD_COUNT_PER_PAGE = 10;

//하단에 표시되는 내비게이션의 페이지 수
export const PAGE_LIST_SIZE = 5;

export const showCountOptions = [
    { value: "10", name: "10개씩" },
    { value: "20", name: "20개씩" },
    { value: "50", name: "50개씩" },
];



export const showEnableOptions = [
    { value: "true", name: "활성"},
    { value: "false", name: "비활성"},
];

export const showDistrictOptions = [
    { value: "SEOUL", name: "서울특별시" },
    { value: "BUSAN", name: "부산광역시" },
    { value: "DAEGU", name: "대구광역시" },
    { value: "INCHEON", name: "인천광역시" },
    { value: "GWANGJU", name: "광주광역시" },
    { value: "DAEJEON", name: "대전광역시" },
    { value: "ULSAN", name: "울산광역시" },
    { value: "SEJON", name: "세종특별자치시" },
    { value: "GYEONGGI", name: "경기도" },
    { value: "GANGWON", name: "강원도" },
    { value: "CHUNGBUK", name: "충청북도" },
    { value: "CHUNGNAM", name: "충청남도" },
    { value: "JEONBUK", name: "전라북도" },
    { value: "JEUNNAM", name: "전라남도" },
    { value: "GYEONGBUK", name: "경상북도" },
    { value: "GYEONGNAM", name: "경상남도" },
    { value: "JEJU", name: "제주특별자치도" },
];

export const showcalcClassOptions = [
    { value: "CALC_CLASS_TYPE_A", name: "정산A-4회" },
    { value: "CALC_CLASS_TYPE_B", name: "정산B-2회" },
    { value: "CALC_CLASS_TYPE_C", name: "정산C-1회" },
];

export const showcalcTypeOptions = [
    { value: "CALC_TYPE_01", name: "정산01-수영/임청" },
    { value: "CALC_TYPE_02", name: "정산02-수영/임영" },
    { value: "CALC_TYPE_03", name: "정산03-수청/임청" },
    { value: "CALC_TYPE_04", name: "정산03-수청/임없" },
];

export const showMealTypeOptions = [
    { value: "GENERAL", name: "전식"},
    { value: "BREAKFAST", name: "조식"},
    { value: "LUNCH", name: "중식"},
    { value: "DINNER", name: "석식"},
    { value: "MULTI", name: "멀티"},
];

export const showMealTypeOptions2 = [
    { value: "GENERAL", name: "전식"},
    { value: "BREAKFAST", name: "조식"},
    { value: "LUNCH", name: "중식"},
    { value: "DINNER", name: "석식"},
];

export const showCustomerTypeOptions4Users = [
    { value: "GENERAL", name: "일반"},
    { value: "STUDENT", name: "학생"},
    { value: "EMPLOYEE", name: "교직원"},
];

export const showSingupTypeOptions = [
    { value: "HAKSIK", name: "학식"},
    { value: "KAKAO", name: "카카오"},
    { value: "NAVER", name: "네이버"},
    { value: "GOOGLE", name: "구글"},
    { value: "APPLE", name: "애플"},
];

export const showTicketTypeOptions = [
    { value: "PAYNOW", name: "일반"},
    { value: "PREPAID", name: "선불"},
    { value: "PAYLATER", name: "후불"},
    { value: "SERVICE", name: "서비스"},
    { value: "PROMOTION", name: "보너스"},
];

export const showTicketTypeOptions2 = [
    { value: "PAYNOW", name: "일반"},
    { value: "PREPAID", name: "선불"},
    { value: "PAYLATER", name: "후불"},
];

export const showSalesStatusTypeOptions = [
    { value: "SALESGO", name: "판매"},
    { value: "SALESSTOP", name: "판매중지"},
    { value: "SOLDOUT", name: "품절"},
];

export const showGopublicTypeOptions = [
    { value: "OPENPUBLIC", name: "공개"},
    { value: "CLOSEPUBLIC", name: "비공개"},
];

export const showPayMethodTypeOptions = [
    { value: "CARD", name: "신용카드-모바일"},
    { value: "PAYLATER", name: "후불"},
    { value: "POINT", name: "전액포인트"},
    { value: "ZERO", name: "0원결제"},
    { value: "CHARGE", name: "충전금"},
    { value: "KIOSK", name: "신용카드-키오스크"},
];

export const showOrderPlatformTypeOptions = [
    { value: "MOBILE", name: "모바일앱"},
    { value: "KIOSK", name: "키오스크"},
];

export const showAccessAllShopTypeOptions = [
    { value: "FIXEDSHOP", name: "지정식당"},
    { value: "ALLSHOP", name: "모든식당"},
];

export const showSalesDayOneTypeOptions = [
    { value: "NOLIMIT", name: "무제한"},
    { value: "JUSTONE", name: "하루한번"},
];

export const showSalesLimitTypeOptions = [
    { value: "ALLTARGET", name: "제한없음"},
    { value: "LIMITEDTARGET", name: "판매대상 제한"},
];

export const showTicketUseTypeOptions = [
    { value: "FORBIDDEN", name: "사용금지"},
    { value: "ALLOW", name: "사용"},
];

export const showPointLogTypeOptions = [
    { value: "USED", name: "식권구매"},
    { value: "GOODS", name: "상품구매"},
    { value: "EXPIRE", name: "소멸"},
];

export const showPointLogTypeGetOptions = [
    { value: "ISSUED", name: "지급"},
    { value: "ROULETTE", name: "룰렛"},
    { value: "ADS", name: "광고"},
    { value: "REFUND", name: "환불"},
];

export const showHoursOptions = [
    { value: "00", name: "00시"},
    { value: "01", name: "01시"},
    { value: "02", name: "02시"},
    { value: "03", name: "03시"},
    { value: "04", name: "04시"},
    { value: "05", name: "05시"},
    { value: "06", name: "06시"},
    { value: "07", name: "07시"},
    { value: "08", name: "08시"},
    { value: "09", name: "09시"},
    { value: "10", name: "10시"},
    { value: "11", name: "11시"},
    { value: "12", name: "12시"},
    { value: "13", name: "13시"},
    { value: "14", name: "14시"},
    { value: "15", name: "15시"},
    { value: "16", name: "16시"},
    { value: "17", name: "17시"},
    { value: "18", name: "18시"},
    { value: "19", name: "19시"},
    { value: "20", name: "20시"},
    { value: "21", name: "21시"},
    { value: "22", name: "22시"},
    { value: "23", name: "23시"},
];

export const showMinutesOptions = [
    { value: "00", name: "00분"},
    { value: "10", name: "10분"},
    { value: "20", name: "20분"},
    { value: "30", name: "30분"},
    { value: "40", name: "40분"},
    { value: "50", name: "50분"},
];

export const showAskTypeOptions = [
    { value: "ETC", name: "기타"},
    { value: "GENERAL", name: "일반문의"},
    { value: "REFUND", name: "식권환불"},
    { value: "TICKET_ERROR", name: "식권사용오류"},
    { value: "SYSTEM_ERROR", name: "이용장애"},
    { value: "PROPOSAL", name: "개선제안"},
    { value: "RIGHTS", name: "권리침해신고"},
];

export const showAnswerStatusOptions = [
    { value: "NOT_ANSWERED", name: "미답변"},
    { value: "ANSWERED", name: "답변완료"},
];

export const showNoticeOnTopOptions = [
    { value: "NORMAL", name: "일반"},
    { value: "ONTOP", name: "상단고정"},
];

export const showAlarmTypeOptions = [
    { value: "GENERAL", name: "알림"},
    { value: "EVENT", name: "이벤트"},
    { value: "SCHOOL", name: "학교"},
];

export const showFaqTypeOptions = [
    { value: "ETC", name: "기타"},
    { value: "TICKET", name: "식권구매"},
    { value: "REFUND", name: "환불"},
    { value: "POINT", name: "포인트"},
];

export const showGoodsTypeOptions = [
    { value: "DRINK", name: "음료"},
    { value: "CVS", name: "편의점"},
    { value: "BAKERY", name: "제과"},
    { value: "FOOD", name: "푸드"},
    { value: "GIFTCARD", name: "상품권"},
];

export const showMenuShowTypeOptions = [
    { value: "ALL", name: "제한없음"},
    { value: "MOBILE", name: "모바일앱만 표시"},
    { value: "KIOSK", name: "키오스크만 표시"},
];

export const showStudentIdCheckTypeOptions = [
    { value: "NONE", name: "안함"},
    { value: "CHECK", name: "학번체크"},
];


export function autoHypenPhone(str) {
    if (str == null) return '';
    str = str?.replace(/[^0-9]/g, '');
    var tmp = '';
    if( str?.length < 4){
        return str;
    }else if(str?.length < 7){
        tmp += str?.substr(0, 3);
        tmp += '-';
        tmp += str?.substr(3);
        return tmp;
    }else if(str?.length < 11){
        tmp += str?.substr(0, 3);
        tmp += '-';
        tmp += str?.substr(3, 3);
        tmp += '-';
        tmp += str?.substr(6);
        return tmp;
    }else{              
        tmp += str?.substr(0, 3);
        tmp += '-';
        tmp += str?.substr(3, 4);
        tmp += '-';
        tmp += str?.substr(7);
        return tmp;
    }
}

export function checkEnabledValue(enabled) {
    if (enabled)
      return "true"
    else
      return "false"
}



export function EnableButton(buttonName) {
    const query = 'button[name="' + buttonName + '"]';
    const selectedEls = document.querySelectorAll(query);

    selectedEls.forEach(button => button.disabled = false);
}

export function DisableButton(buttonName) {
    const query = 'button[name="' + buttonName + '"]';
    const selectedEls = document.querySelectorAll(query);

    selectedEls.forEach(button => button.setAttribute('disabled','disabled'));
}



