import { call, put, takeLatest } from "redux-saga/effects";
import * as actions from "../actions/loginActions";

// import { startLoading, finishLoding, LOADING_STATE } from "../modules/loadingReducer";
 
function* loginSaga(action) {
    // yield put(startLoading(LOADING_STATE));
    
    try {
        const { data } = yield call(action.apicall, action.payload);
        yield put(actions.loginLoginSuccess(data));
    } catch (error) {
        yield put(actions.loginLoginFail(error));
    }

    // yield put(finishLoding(LOADING_STATE));
}
 
export default function* watchLoginSaga() {
    yield takeLatest(actions.LOGIN_REQUEST, loginSaga);
}