import { call, put, takeLatest } from "redux-saga/effects";
import * as actions from "../actions/apiDataActions";

import { startLoading, finishLoding, LOADING_STATE } from "../modules/loadingReducer";
 
function* apiGetDataSaga(action) {
    yield put(startLoading(LOADING_STATE));
    
    try {
        const { data } = yield call(action.apicall, action.payload);
        yield put(actions.apiGetDataSuccess(data));
    } catch (error) {
        yield put(actions.apiGetDataFail(error));
    }

    yield put(finishLoding(LOADING_STATE));
}
 
export default function* watchApiGetDataSaga() {
    yield takeLatest(actions.API_GET_DATA, apiGetDataSaga);
}