import * as actions from "../actions/apiDataActions";

const apiDataState = {}

function apiDataReducer(state=apiDataState, action) {
    switch(action.type){
        case actions.API_GET_DATA:
          return {
            ...state,
            payload: action.payload,
            data: {},
            error: {}
          }
        case actions.API_GET_DATA_SUCCESS:
          return {
            ...state,
            data: action.data
          }
        case actions.API_GET_DATA_FAIL:
          return {
            ...state,
            error: action.error
          }
        default:
          return state;
      }
    };

export default apiDataReducer;