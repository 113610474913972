import { createStore, applyMiddleware, compose, combineReducers } from 'redux';
import createSagaMiddleware from 'redux-saga';
import rootSaga from '../reducers/rootSaga';

import ThemeOptions from '../reducers/ThemeOptions';
import apiDataReducer from '../reducers/modules/apiDataReducer';
import loginReducer from '../reducers/modules/loginReducer';
import loading from '../reducers/modules/loadingReducer';

const sagaMiddleware = createSagaMiddleware();

const rootReducer = combineReducers({
  loginReducer,  
  ThemeOptions,
  apiDataReducer,
  loading
});

const composeEnhancers =
  typeof window === 'object' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
        // Specify extension’s options like name, actionsDenylist, actionsCreators, serialize...
      })
    : compose;

const enhancer = composeEnhancers(
  applyMiddleware(sagaMiddleware)
  // other store enhancers if any
);
const store = createStore(rootReducer, enhancer);

sagaMiddleware.run(rootSaga);

export default store;